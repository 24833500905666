@import url(https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
.CalciteApp {
  text-align: center;
}

.CalciteApp-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .CalciteApp-logo {
    animation: CalciteApp-logo-spin infinite 20s linear;
  }
}
*/
/*
.CalciteApp-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.CalciteApp-link {
  color: #61dafb;
}

@keyframes CalciteApp-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
div.button, div.text-container 
.button div.text-container {
	line-height: 1.1rem;
}
.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
div.content-area {
	background-color: var(--calcite-ui-foreground-1);
	padding: .5em;
}
body {
	line-height: 1.4rem;;
}
calcite-tip.floating-tip{
	position: absolute;
	bottom:0;
	left: 0; 
	right: 0; 
	margin-left: auto; 
	margin-right: auto; 
	width:clamp(70vw, 50vw, 25vw);
	z-index: 9999;
	
}
calcite-stepper-item.no-outline {
	outline:none;
	outline-width: unset;
}
table.disease-table {
	max-width: 100%;
	border-collapse: collapse;
}
table.disease-table tbody tr{
	background-color:var(--calcite-ui-foreground-1);
}
table.disease-table tbody tr:nth-child(odd){
	background-color:var(--calcite-ui-foreground-3);
}
table.disease-table thead tr th{
	text-align: left;
	font-weight: bold;;
}
table.disease-table tbody tr th,
table.disease-table tbody tr td{
	border-top: 1px solid #ddd;
	padding: 8px;
}

.layer-options {
  border-bottom: 2px solid black;
}
.hide-options {
  display:none;
}
