@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";

div.content-area {
	background-color: var(--calcite-ui-foreground-1);
	padding: .5em;
}
body {
	line-height: 1.4rem;;
}
calcite-tip.floating-tip{
	position: absolute;
	bottom:0;
	left: 0; 
	right: 0; 
	margin-left: auto; 
	margin-right: auto; 
	width:clamp(70vw, 50vw, 25vw);
	z-index: 9999;
	
}
calcite-stepper-item.no-outline {
	outline:none;
	outline-width: unset;
}
table.disease-table {
	max-width: 100%;
	border-collapse: collapse;
}
table.disease-table tbody tr{
	background-color:var(--calcite-ui-foreground-1);
}
table.disease-table tbody tr:nth-child(odd){
	background-color:var(--calcite-ui-foreground-3);
}
table.disease-table thead tr th{
	text-align: left;
	font-weight: bold;;
}
table.disease-table tbody tr th,
table.disease-table tbody tr td{
	border-top: 1px solid #ddd;
	padding: 8px;
}

.layer-options {
  border-bottom: 2px solid black;
}
.hide-options {
  display:none;
}